<template>
    <div class="wrapper-exp01">
        <div class="container">
            <div class="card">
                <h2>Glassmorphism</h2>
            </div>
        </div>
    </div> 
</template>

<script>

    export default {
        name: 'Exp01',
        mounted() {
          
        },
        methods: {
          
        }
    }
</script>

<style scoped>

    /* Background styles */
    .wrapper-exp01 {
        background-image: url('/img/P1221414.JPG');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    /* Glassmorphism card effect */
    .card {
        display: flex;
        padding: 20px;
        min-height: 380px;
        text-align: center;
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 12px;
        border: 1px solid rgba(209, 213, 219, 0.3);
        width: 800px;
    }

</style>
