import VueRouter from 'vue-router'
import Home from "@/components/Home.vue";
import Labs from "@/components/Labs.vue";
import Exp01 from "@/components/Labs/glassmorphism.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/labs",
        name: "Labs",
        component: Labs,
    },
    {
        path: "/labs/exp01",
        name: "Exp01",
        component: Exp01,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})

export default router;