<template>
    <div class="wrapper">
        <header>
            <div id="particles-header"></div>
        </header>
        <div class="container">
            <main role="main" class="pb-3">
                <div class="container">

                    <div id="about-me" class="container d-flex flex-column bg-white mx-auto jb-shadow">
                        <div id="about-me-content" class="row flex-grow-1">
                            <div class="col-md-5 col-sm-12 py-4 px-4">
                                <h1 class="name">Joris <br /> Beekhuizen</h1>
                            </div>
                            <div class="col-md-7 col-sm-12 py-4 px-4 text-lg-center">
                                <h2>About Me</h2>
                                <p>
                                    Software Developer (.NET) | Web & Azure | fotografie <a href="https://photos.jorisbeekhuizen.nl"  class="inline-link" target="_blank" title="naar foto's" rel="nofollow"><i class="bi bi-box-arrow-up-right"></i></a> 
                                    | Home Automation | lezen | leren | wandelen &amp; fietsen
                                 </p>
                            </div>
                        </div>
                        <div class="row">
                            <div id="about-me-footer" class="col-12 d-flex justify-content-center">
                                <a href="https://nl.linkedin.com/in/jorisbeekhuizen" target="_blank" class="icon-link d-flex" title="Linkedin"><i class="bi bi-linkedin align-self-center"></i></a>
                                <a href="https://photos.jorisbeekhuizen.nl" class="icon-link d-flex" target="_blank" title="foto's" rel="nofollow"><i class="bi bi-camera2 align-self-center"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="row my-skills">
                        <div class="col-12 text-center">
                            <h2>Skills</h2>
                            <ul class="tags py-4">
                                <li class="s-tag">C#</li>
                                <li class="s-tag">.NET Core</li>
                                <li class="s-tag">Azure</li>
                                <li class="s-tag">Web Applications / API</li>
                                <li class="s-tag">SQL Databases</li>
                                <li class="s-tag">DevOps</li>
                                <li class="s-tag">Scrum</li>
                                <li class="s-tag">GIT</li>
                                <li class="s-tag">Vue.js</li>
                                <li class="s-tag">Javascript</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <footer class="border-top footer text-muted">
            <div class="container">
                <div class="row">
                    <div class="col">
                        &copy; 2013-{{currentyear}} jorisbeekhuizen.nl
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import 'particles.js'

    export default {
        name: 'Home',
        data: function () {
            return {
                currentyear: new Date().getFullYear()
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initHeader();
            })
        },
        methods: {
            initHeader() {

                // load particles-js
                // eslint-disable-next-line no-undef
                particlesJS.load('particles-header', 'js/particlesheader-config.json', function () {
                    //console.log('callback - particles-js config loaded');
                });
            }
        }
    }
</script>

<style scoped>

    body {
        margin-bottom: 60px;
        background-color: #efefef;
    }

    h1, h2, h3, h4, h5 {
        font-family: 'Raleway', sans-serif;
        font-weight: 100;
    }

    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    header {
        background-image: linear-gradient(135deg, #003073, #029797);
        width: 100%;
        height: 450px;
    }

        header > div {
            width: 100%;
            height: 100%;
        }

    @media (max-width: 768px) {
        header {
            height: 350px;
        }
    }

    /* Provide sufficient contrast against white background */
    a {
        color: #0366d6;
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .jb-shadow {
        box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%);
    }


    /* Sticky footer styles
    -------------------------------------------------- */
    .border-top {
        border-top: 1px solid #e5e5e5;
    }

    .border-bottom {
        border-bottom: 1px solid #e5e5e5;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        white-space: nowrap;
        line-height: 60px;
    }

    /*  about */
    #about-me {
        position: relative;
        min-height: 400px;
        margin-top: -250px;
        max-width: 825px;
        font-weight: 300;
        font-size: 1.3em;
        border-radius: 1em;
    }
    
        @media (max-width: 768px) {
             #about-me {
                font-size: 1em;
            }
        }

    #about-me-content {
        padding: 1em 0.5em;
    }

    #about-me-footer {
        height: 70px;
        background-color: #016b87; /*#2196f3;*/
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
    }

        #about-me-footer > a {
            color: #fff;
        }

    #about-me h1 {
        font-size: 3em;
        font-weight: 500;
        color: #016b87;
    }
    #about-me h2 {
        font-size: 1.5em;
    }
    #about-me p{
        font-size: 1.2em;
    }

        #about-me p a {
            color:#47a1ba;
        }

    #about-me .inline-link {
        font-size: 0.7em;
        vertical-align: top;
        color: #2d6984;
    }

    /* tags */
    ul.tags{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .s-tag {
        display: inline-block;
        padding: 0.5em;
        margin: 0 0.8em 0.8em 0;
        border: 1px solid #808080;
        border-radius: 0.5em;
    }

    .icon-link {
        width: 3em;
        justify-content: center;
        font-size: 1.5em;
    }

        .icon-link i {
            transition: 0.4s;
        }

        .icon-link:hover i {
            color: #c8f0fb;
            font-size: 1.2em;
        }

    .my-skills{
        margin-top: 4em;
    }
</style>
